import PrivateRoutes from "app/auth/privateRoutes";
import { lazy } from "react";
import clienteRoutes from "../Clientes/routes";
import ControlDash from "app/auth/R";
import processoRoutes from "../processo/routes";
import projectoRoutes from "../projecto/routes";
import tecnicoRoutes from "../tecnico/routes";
import pedidosRoutes from "../pedidos/routes";
import { Navigate } from "react-router-dom";
import usuariosRoutes from "../usuario/routes";
import VistosRoutes from "../visas/routes";
import AccessLogsRoutes from "../logs/routes";
import FuncionariosRoutes from "../funcionarios/routes";
import DepartamentoRoutes from "../departamento/routes";
import cargoRoutes from "../departamento/cargos/routes";
import { authRoles } from "app/auth/authRoles";
import AuthGuard from "app/auth/AuthGuard";
import salarioRoutes from "../salario/routes";
import HorariosRoutes from "../horariosTurnos/routes";

const cliente = JSON.parse(localStorage.getItem("user"));
const adminRoutes = [
    {
        path: "/dashboard/default",
        element: <AuthGuard > <ControlDash></ControlDash></AuthGuard>
    },

    ...clienteRoutes,
    ...processoRoutes,
    ...projectoRoutes,
    ...tecnicoRoutes,
    ...pedidosRoutes,
    ...usuariosRoutes,
    ...VistosRoutes,
    ...AccessLogsRoutes,
    ...FuncionariosRoutes,
    ...DepartamentoRoutes,
    ...cargoRoutes,
    ...salarioRoutes,
    ...HorariosRoutes,
];


export default adminRoutes;