import { Fragment, useEffect, useState } from "react";
import { Box, Card, Grid, styled, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import { CChart } from "@coreui/react-chartjs";
import { CAlert, CCallout, CCol, CContainer, CRow } from "@coreui/react";
import { generateBreadcrumbs } from "app/utils/generateBreadcrumbs";
import { Cliente } from "../Clientes/util";
import { Projecto } from "../projecto/util";
import { Usuario } from "../usuario/util";
import Processo from "../processo/util";
import Visto from "../visas/util";
import { Group, Folder, Wallet, FileCopySharp, Person, FileOpen } from "@mui/icons-material";
import Ficheiros from "app/utils/Ficheiros";

// STYLED COMPONENTS
const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const Title = styled("span")(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  marginRight: ".5rem",
  textTransform: "capitalize",
  backgroundColor: theme.palette.primary.main, // Cor de fundo do título
  color: theme.palette.common.white, // Cor do texto
  padding: "5px 10px", // Adiciona um pouco de espaço ao redor do texto
  borderRadius: "4px" // Bordas arredondadas
}));

const SubTitle = styled("span")(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.palette.text.secondary
}));

const ChartCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper, // Cor de fundo do card
  border: `1px solid ${theme.palette.divider}`, // Borda do card
  borderRadius: "8px", // Bordas arredondadas
  padding: "16px", // Adiciona padding ao card
  boxShadow: theme.shadows[1] // Sombra do card
}));

export default function DashAdminProjecto() {
  const { palette } = useTheme();
  const location = useLocation();
  const routeSegments = generateBreadcrumbs(location);

  // State variables
  const [totalProcesso, setTotalProcesso] = useState(0);
  const [totalCliente, setTotalCliente] = useState();
  const [totalProjecto, setTotalProjecto] = useState();
  const [totalUsuario, setTotalUsuario] = useState();
  const [totalVisto, setTotalVisto] = useState(0);
  const [totalDocumentos, setTotalDocumentos] = useState(0);
  const [processosMensais, setProcessosMensais] = useState([]);
  const [processosDiarios, setProcessosDiarios] = useState({ labels: [], counts: [] });
  const [processosHoje, setProcessosHoje] = useState([]);
  const [processoStatus, setProcessoStatus] = useState({});
  // Function to fetch data
  async function fetchData() {
    const cliente = new Cliente();
    const projecto = new Projecto();
    const usuario = new Usuario();
    const processo = new Processo();
    const visto = new Visto();
    const ficheiros = new Ficheiros();


    setTotalCliente(await cliente.contar());
    setTotalProjecto(await projecto.contar());
    setTotalUsuario(await usuario.contar());
    setTotalVisto(await visto.contar());
    setTotalProcesso(await processo.contar());
    setTotalDocumentos(await ficheiros.contar());

    // Fetch statistics data
    setProcessosMensais(await processo.listarEstatisticaMensal());
    const diaria = await processo.listarEstatisticaDiaria();
    setProcessosDiarios(formatProcessosDiarios(diaria));
    setProcessosHoje(await processo.listarEstatisticaHoje());
    setProcessoStatus(await processo.listarEstatisticaHoje());
  }

  // Function to format daily process statistics
  const formatProcessosDiarios = (data) => {
    const labels = Object.keys(data);
    const counts = labels.map(period => {
      return data[period] && data[period].length > 0 ? data[period][0].count : 0;
    });

    return {
      labels,
      counts
    };
  };
  const formatProcessosHoje = (data) => {
    const periods = ["Morning", "Afternoon", "Evening", "Night"];
    const counts = periods.map(period => {
      const periodData = data[period];
      if (Array.isArray(periodData) && periodData.length > 0) {
        return periodData[0].count; // Retorna a contagem se disponível
      }
      return 0; // Retorna 0 se não houver dados
    });

    return {
      labels: periods,
      counts: counts
    };
  };
  const transformProcessosMensais = (processos) => {
    const meses = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const dataCounts = meses.map(mes => {
      const processosMes = processos[mes];
      return processosMes && processosMes.length > 0 ? processosMes[0].count : 0;
    });

    return dataCounts;
  };
  // Transform the status data for the pie chart
  const transformProcessoStatus = (statusData) => {
    const labels = Object.keys(statusData);
    const data = labels.map(label => statusData[label].length > 0 ? statusData[label][0].count : 0);
    return { labels, data };
  };

  const { labels: statusLabels, data: statusCounts } = transformProcessoStatus(processoStatus);

  const processCounts = transformProcessosMensais(processosMensais);
  useEffect(() => {
    fetchData();
  }, []);

  // Card data
  const cardList = [
    {
      name: "Clientes",
      amount: totalCliente,
      Icon: Group,
      bgColor: "info",
      path: "/clientes/list"
    },
    {
      name: "Projectos",
      amount: totalProjecto,
      Icon: Folder,
      bgColor: "info",
      path: "/projectos/list"
    },
    {
      name: "Processos",
      amount: totalProcesso,
      Icon: Wallet,
      bgColor: "info",
      path: "/processos/list"
    },
    {
      name: "Files",
      amount: totalDocumentos,
      Icon: FileCopySharp,
      bgColor: "info",
      path: "/documentos/list"
    },
    {
      name: "Usuários",
      amount: totalUsuario,
      Icon: Person,
      bgColor: "info",
      path: "/usuarios/list"
    },
    {
      name: "Vistos",
      amount: totalVisto,
      Icon: FileOpen,
      bgColor: "info",
      path: "/vistos/query?activo=null"
    }
  ];

  return (
    <Fragment>
      <ContentBox className="analytics h-auto">
        <Box className="breadcrumb">
          <Breadcrumb routeSegments={routeSegments} />
        </Box>
        <Box pt={2}></Box>

        <SimpleCard>
          <CRow>
            {cardList.map((card, index) => (
              <CCol key={index}>
                <Link to={card.path}>
                  <CCallout color={card.bgColor}>
                    <div>
                      <strong>{card.name}</strong>
                      <h1>{card.amount}</h1>
                      <card.Icon />
                    </div>
                  </CCallout>
                </Link>
              </CCol>
            ))}
          </CRow>
        </SimpleCard>

        <Grid container spacing={3}>

          {/* Monthly Statistics Bar Chart */}
          <Grid item lg={8} md={8} sm={12} xs={12}>
            {/* Daily Statistics Bar Chart */}
            <ChartCard sx={{ mb: 3 }}>
              <CAlert color="info">
                <Title>Estatística Diária dos Pedidos</Title>
              </CAlert>
              <CChart
                type="bar" // Mudou para bar conforme solicitado
                data={{
                  labels: processosDiarios.labels,
                  datasets: [
                    {
                      label: "Pedidos Diários",
                      backgroundColor: "#FF6384", // Cor do gráfico
                      borderColor: "#FF4C40", // Borda do gráfico
                      borderWidth: 2, // Largura da borda
                      data: processosDiarios.counts // Passa os dados formatados
                    }
                  ]
                }}
                options={{
                  plugins: {
                    legend: { display: true } // Exibe a legenda
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      grid: {
                        color: palette.grey[300] // Cor das linhas de grade
                      }
                    },
                    x: {
                      grid: {
                        color: palette.grey[300] // Cor das linhas de grade
                      }
                    }
                  }
                }}
              />
            </ChartCard>
            <ChartCard sx={{ mb: 3 }}>
              <CAlert color="info">
                <Title>Estatística Mensal dos Pedidos</Title>
              </CAlert>
              <CChart
                type="line"
                data={{
                  labels: [
                    "Janeiro", "Fevereiro", "Março", "Abril", "Maio",
                    "Junho", "Julho", "Agosto", "Setembro",
                    "Outubro", "Novembro", "Dezembro"
                  ],
                  datasets: [
                    {
                      label: "Pedidos Mensais",
                      backgroundColor: "#42A5F5", // Cor consistente com o relatório de pagamento
                      borderColor: "#1E88E5",
                      data: processCounts
                    }
                  ]
                }}
                options={{
                  plugins: {
                    legend: { display: true } // Exibe a legenda
                  }
                }}
              />
            </ChartCard>


          </Grid>

          {/* Today's Statistics Doughnut Chart */}
          <Grid item lg={4} md={8} sm={6} xs={12}>
            <ChartCard sx={{ mb: 3 }}>
              <Title>Relatório de Hoje</Title>
              <SubTitle>Pedidos de hoje</SubTitle>
              <CChart
                type="doughnut"
                data={{
                  labels: formatProcessosHoje(processosHoje).labels, // Use as labels formatadas
                  datasets: [
                    {
                      label: "Pedidos de Hoje",
                      backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"], // Adicione uma cor para cada período
                      data: formatProcessosHoje(processosHoje).counts // Use as contagens formatadas
                    }
                  ]
                }}
                options={{
                  plugins: {
                    legend: { display: true }
                  }
                }}
              />

            </ChartCard>
            <Card sx={{ px: 2, py: 4, mb: 3 }}>
              <CAlert color="info">
                <Title>Status dos Processos</Title>
              </CAlert>
              <CChart
                type="pie"
                data={{
                  labels: statusLabels,
                  datasets: [
                    {
                      label: "Status dos Processos",
                      backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#42A5F5", "#1E88E5"], // Adicione mais cores conforme necessário
                      data: statusCounts
                    }
                  ]
                }}
                options={{
                  plugins: {
                    legend: { display: true }
                  }
                }}
              />
            </Card>
          </Grid>

        </Grid>
      </ContentBox>
    </Fragment>
  );
}
